import React, { useState } from "react";
import "../styles/pop_modal.css";
import axios from "axios";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
export default function Pop_modal({ ismodalopen, setIsmodalopen, storeid }) {
  const [customer_no, setcustomer_no] = useState();
  const [max_mins, setmax_mins] = useState();
  const [initiate_call, setinitiate_call] = useState();
  const [amount_paid, setamount_paid] = useState(0);
  const [selecteditem, setselecteditem] = useState(1);

  async function handleadd(event) {
    event.preventDefault();
    await axios
      .post("http://154.62.107.18:8083/api/schedule", {
        customer_no,
        max_mins,
        initiate_call,
        amount_paid,
        selecteditem,
        storeid,
      })
      .then((res) => {
        console.log(res, "im new data");
        document.location.reload();
      })
      .catch((err) => console.log(err));

    setIsmodalopen(false);
  }
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const dropdownStyles = {
    backgroundColor: "rgb(0,123,255)",
    color: "white",
    border: "2px solid #ccc",
    borderRadius: "4px",
    padding: "1px",
    width: "110px",
    left: "50px",
    position: "absolute",
    height: "45px",
    // zIndex:'2',
    margin: "0px",
  };
  return (
    <div id="container">
      <Modal
        size="small"
        isOpen={ismodalopen}
        toggle={() => setIsmodalopen(!ismodalopen)}
      >
        <ModalHeader toggle={() => setIsmodalopen(!ismodalopen)}>
          Initiate Call
        </ModalHeader>
        <ModalBody>
          <form>
            <Row>
              <Col lg={12}>
                <div className="form-content">
                  <label>Customer Number -</label>
                  {/* <input type="number" placeholder="enter number" min="0" max="10" className="form-input" step="1"/> */}
                  <input
                    type="text"
                    name="myNumber"
                    max="9999999999"
                    maxLength="10"
                    className="form-input"
                    onChange={(e) => {
                      setcustomer_no(e.target.value);
                    }}
                  />
                </div>
                <div className="form-content">
                  <label>Max. Number of Mins -</label>
                  {/* <input type="text" placeholder="enter max-mins" className="form-input"/> */}
                  <input
                    type="number"
                    name="myMinutes"
                    min="0"
                    max="59"
                    step="1"
                    className="form-input"
                    onChange={(e) => {
                      setmax_mins(e.target.value);
                    }}
                  />
                </div>
                <div className="form-content">
                  <label>Type of schedule -</label>
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggleDropdown}
                    id="modal"
                    style={{ color: "rgb(0,123,255)" }}
                  >
                    <DropdownToggle caret style={dropdownStyles}>
                      {selecteditem ? selecteditem : "Select an item"}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => setselecteditem(1)}>
                        Type 1
                      </DropdownItem>
                      <DropdownItem onClick={() => setselecteditem(2)}>
                        Type 2
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div className="form-content">
                  <label>Initiate Call At -</label>
                  <input
                    type="datetime-local"
                    placeholder="enter call time"
                    className="form-input"
                    onChange={(e) => {
                      setinitiate_call(e.target.value);
                    }}
                  />
                </div>
                <div className="form-content">
                  <label>Amount Paid -</label>
                  <input
                    type="number"
                    placeholder="enter amount"
                    className="form-input"
                    onChange={(e) => {
                      setamount_paid(e.target.value);
                    }}
                  />
                </div>

                <button
                  onClick={(e) => handleadd(e)}
                  className="links"
                  style={{ height: "45px" }}
                >
                  SUBMIT
                </button>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
}
