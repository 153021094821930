import React, { useState, useEffect } from "react";
import "../styles/consultant_details.css";
import axios from "axios";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import PopModal from "./pop_modal";
import Consultant_call from "./consultant_call";
export default function Consultant_details() {
  const [consultantdata, setconsultantdata] = useState([]);
  const [storeid, setstoreid] = useState(0);
  useEffect(() => {
    axios.get("http://154.62.107.18:8083/api/get").then((data) => {
      setconsultantdata(data.data[0]);
      // console.log(consultantdata[0], "im data");
    });
  }, []);

  const columns = [
    {
      name: "Consultant ID",
      selector: (row) => row.consultant_ID,
      sortable: true,
    },
    {
      name: "Consultant Name",
      selector: (row) => row.consultant_name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.consultant_status,
      sortable: true,
    },
    {
      name: "Consultant number",
      selector: (row) => row.consultant_mob_no,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <Link
          to="/consultant_details"
          state={{ some: "value" }}
          // onClick={() => checkfunction(row)}
          onClick={() => {
            setIsmodalopen(true);
            setstoreid(row.consultant_ID);
          }}
          className="links"
        >
          Initiate Call 
          {/* {storeid} */}

        </Link>
      ),
    },
  ];

  // const [filtereddata, setfiltereddata] = useState(consultantdata);
  // const handlefilter = (event) => {
  //   const newdata = consultantdata.filter((row) => {
  //     return row.name.toLowerCase().includes(event.target.value.toLowerCase());
  //   });
  //   setfiltereddata(newdata);
  // };
  const [name, setname] = useState("");
  const [number, setnumber] = useState();
  const [status, setstatus] = useState();
  async function handleadd(event) {
    event.preventDefault();
    const id = consultantdata.length + 1;
    if (name.length && number.length && status.length > 0) {
      await axios
        .post("http://154.62.107.18:8083/api/create", {
          newid: id,
          name,
          number,
          status,
        })
        .then((res) => {
          console.log(res.config.data, "im new data");
          document.location.reload();
        })
        .catch((err) => console.log(err));
    } else {
      alert("Please enter all details !! ");
    }
  }

  const [ismodalopen, setIsmodalopen] = useState(false);
  return (
    <div id="container">
      <div id="input-container">
        <div id="input_content">
          {/* <input type="text" placeholder="filter here" /> */}
          <div>
            <h4>Enter name</h4>
            <input
              type="text"
              placeholder="Enter name"
              onChange={(e) => setname(e.target.value)}
              className="all-inputs"
            />
          </div>
          <div>
            <h4>Enter number</h4>
            <input
              type="text"
              name="myNumber"
              max="9999999999"
              maxLength="10"
              placeholder="Enter number"
              className="all-inputs"
              onChange={(e) => {
                setnumber(e.target.value);
              }}
            />
          </div>
          <div>
            <h4>Enter status</h4>
            <input
              type="number"
              placeholder="Enter status"
              onChange={(e) => setstatus(e.target.value)}
              className="all-inputs"
            />
          </div>
          <button onClick={(e) => handleadd(e)} className="links">
            Add
          </button>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={consultantdata}
        fixedHeader
        selectableRows={true}
        pagination
        highlightOnHover
      ></DataTable>
      <PopModal
        ismodalopen={ismodalopen}
        setIsmodalopen={setIsmodalopen}
        storeid={storeid}
      />
      <Consultant_call />
    </div>
  );
}
