import "./App.css";
import Login from "./screens/admin_login";
import Consultant_Details from "./screens/consultant_details";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Pop_modal from "./screens/pop_modal";
function App() {
  return (
    <BrowserRouter>
        <Routes >
          <Route path="/" element={<Login />} />
          <Route
            path="/consultant_details"
            element={<Consultant_Details />}
          />
          <Route
          path="/pop_modal"
          element={<Pop_modal/>}
          />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
