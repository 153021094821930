import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import "../styles/pop_modal.css";
import { Link } from "react-router-dom";
export default function Consultant_call() {
  const [consultantdata, setconsultantdata] = useState([]);
  useEffect(() => {
    axios.get("http://154.62.107.18:8083/api/getschedule").then((data) => {
      setconsultantdata(data.data[0]);
    });
  }, []);
  const columns = [
    {
      name: "Call ID",
      selector: (row) => row.call_id,
      sortable: true,
    },
    {
      name: "Customer Number",
      selector: (row) => row.cl_customer_no,
      sortable: true,
    },
    {
      name: "Max Mins",
      selector: (row) => row.cl_max_mins,
      sortable: true,
    },
    {
      name: "Schedule Time",
      selector: (row) => row.cl_schedule_time,
      sortable: true,
    },
    {
      name: "Amount Paid",
      selector: (row) => row.cl_amount_paid,
      sortable: true,
    },
    {
      name: "Consultant Number",
      selector: (row) => row.consultant_mob_no,
      sortable: true,
    },
    {
      name: "Consultant Name",
      selector: (row) => row.consultant_name,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <Link
          to="/consultant_details"
          state={{ some: "value" }}
          onClick={() => callnow(row)}
          className="links"
        >
          Call Now
        </Link>
      ),
    },
  ];
  function callnow(row) {
    let customer_no = row.cl_customer_no;
    let consultant_no = row.consultant_mob_no;
    let maxmins = row.cl_max_mins;

    axios.get(
      `https://app.callerdesk.io/api/click_to_call_v3?calling_party_a=${customer_no}&calling_party_b=${consultant_no}&deskphone=1206851107&CallLimit=${maxmins}&authcode=602a0473bb9657c28231290929d4b894&call_from_did=1`
    );
    // console.log('call')
  }
  return (
    <div>
      <h1
        style={{
          textAlign: "center",
          fontSize: "2rem",
          fontWeight: "bold",
          color: "rgb(0,123,255)",
          marginBottom: "1rem",
        }}
      >
        Schedule History
      </h1>
      <DataTable
        columns={columns}
        data={consultantdata}
        fixedHeader
        selectableRows={true}
        pagination
        highlightOnHover
      ></DataTable>
    </div>
  );
}
