import React, { useState } from "react";
import "../styles/admin_login.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../styles/pop_modal.css";
export default function Admin_login() {
  const navigate = useNavigate();
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  async function handlesubmit(event) {
    event.preventDefault();
    if (!username || !password) {
      setErrorMessage("Please enter both username and password.");
      return;
    }
    await axios
      .post("http://154.62.107.18:8083/api/login", {
        username: username,
        password: password,
      })
      .then((res) => {
        if (res.data.message === "OK") {
          navigate("/consultant_details");
          setErrorMessage("Login successful");
        } else {
          setErrorMessage("Login failed");
        }
        console.log(res);
      })
      .catch((err) => setErrorMessage("Please enter valid login credentials"));
  }
  return (
    <div id="body">
      <div id="main_content">
        <div id="conatiner">
          <h1 className="header">Login!</h1>
          <div className="user_details">
            <b className="details">Username</b>
            <input
              placeholder="Enter username"
              className="input"
              onChange={(e) => {
                setusername(e.target.value);
              }}
            />
          </div>
          <div className="user_details">
            <b className="details">Password</b>
            <input
              placeholder="Enter password"
              className="input"
              onChange={(e) => {
                setpassword(e.target.value);
              }}
            />
          </div>
          <Link className="links" onClick={handlesubmit}>
            Login
          </Link>
          {errorMessage && <p className="error">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
}
